





























































































































import Vue from 'vue'

import log from '@/log'

import {
  CommissionQuotaPeriod,
  Company,
  SalesArea,
  Salesperson,
  CommissionQuotaAttainmentGroups,
  CommissionComponentGroup,
} from '@/types'

interface Group {
  id: string
  group: {
    company: Company
    salesArea: SalesArea
    salesperson: Salesperson
    commissionQuotaPeriod: CommissionQuotaPeriod
    currency: string
  }
  items: CommissionQuotaAttainmentGroups[]
}

interface CCGroup {
  commissionComponentGroup: CommissionComponentGroup
  commissionQuotaAmount: number
  postedAmount: number
  estPostedAmount: number
  ytdQuotaAmount: number
  items: CommissionQuotaAttainmentGroups[]
}

export default Vue.extend({
  components: {},
  props: {
    period: {
      type: Number,
      default: new Date().getFullYear(),
    },
    outlined: Boolean,

    ccCols: {
      type: [Number, String],
      default: 12,
    },
    ccSm: {
      type: [Number, String],
      default: 6,
    },
    ccMd: {
      type: [Number, String],
      default: 6,
    },
    ccLg: {
      type: [Number, String],
      default: 4,
    },
    ccXl: {
      type: [Number, String],
      default: 4,
    },

    ccgCols: {
      type: [Number, String],
      default: 12,
    },
    ccgSm: {
      type: [Number, String],
      default: 12,
    },
    ccgMd: {
      type: [Number, String],
      default: 10,
    },
    ccgLg: {
      type: [Number, String],
      default: 8,
    },
    ccgXl: {
      type: [Number, String],
      default: 6,
    },
  },
  data: () => ({
    items: [] as CommissionQuotaAttainmentGroups[],

    isFetching: false,
    errors: [] as Error[],

    fetchTimestamp: undefined as number | undefined,
  }),
  watch: {
    period: {
      immediate: true,
      handler() {
        this.getData()
      },
    },
  },
  computed: {
    groups(): Group[] {
      return this.items.reduce(
        (prev: Group[], item: CommissionQuotaAttainmentGroups) => {
          // CO	SALES AREA	SALESPERSON	PERIOD	COMPONENT	CURRENCY
          let g = prev.find(
            p =>
              p.group.company.id === item.company.id &&
              p.group.salesArea.id === item.salesArea.id &&
              p.group.salesperson.id === item.salesperson.id &&
              p.group.commissionQuotaPeriod.id ===
                item.commissionQuotaPeriod.id &&
              p.group.currency === item.currency
          )

          if (!g) {
            g = {
              id: [
                item.company.id,
                item.salesArea.id,
                item.salesperson.id,
                item.commissionQuotaPeriod.id,
                item.currency,
              ].join(':'),
              group: {
                company: item.company,
                salesArea: item.salesArea,
                salesperson: item.salesperson,
                commissionQuotaPeriod: item.commissionQuotaPeriod,
                currency: item.currency,
              },
              items: [],
            }

            prev.push(g)
          }

          g.items.push(item)

          return prev
        },
        []
      )
    },
  },
  methods: {
    ccGroups(items: CommissionQuotaAttainmentGroups[]): CCGroup[] {
      return items.reduce((prev: CCGroup[], item) => {
        let g = prev.find(
          z =>
            z.commissionComponentGroup.id === item.commissionComponent.group.id
        )

        if (!g) {
          g = {
            commissionComponentGroup: item.commissionComponent.group,
            commissionQuotaAmount: 0,
            postedAmount: 0,
            estPostedAmount: 0,
            ytdQuotaAmount: 0,
            items: [],
          }
          prev.push(g)
        }

        g.commissionQuotaAmount =
          g.commissionQuotaAmount + item.commissionQuotaAmount
        g.postedAmount = g.postedAmount + item.postedAmount
        g.estPostedAmount = g.estPostedAmount + item.estPostedAmount
        g.ytdQuotaAmount = g.ytdQuotaAmount + (item.ytdQuotaAmount || 0)

        g.items.push(item)

        return prev
      }, [] as CCGroup[])
    },
    getData(): void {
      this.items = [] // required to refetch child components
      this.isFetching = true
      this.errors = []

      this.$api.commissions.quotas.periods
        .period(this.period)
        .list()
        .then(items => {
          this.items = items
          this.fetchTimestamp = new Date().getTime()
        })
        .catch(err => {
          log.error(err)
          this.errors.push(err)
        })
        .finally(() => {
          this.isFetching = false
        })
    },
  },
})
